import { Injectable, InjectionToken } from '@angular/core';
import { AuthService } from '@scbt-shared/services/auth.service';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

export interface IIsAuthGuardData {
	title?: string;
	message1?: string;
	message2?: string;
	buttonTrueText?: string;
	buttonFalseText?: string;
	redirectToHome?: boolean;
}

export const IS_AUTH_GUARD_DATA_DEF = {
	title: 'Devi essere autenticato',
	message1: null,
	message2: null,
	buttonTrueText: 'ACCEDI',
	buttonFalseText: null,
	redirectToHome: false,
};

export const IS_AUTHENTICATED_GUARD = new InjectionToken('IsAuthenticatedGuard');

@Injectable()
export class IsAuthenticatedGuard {
	constructor(private authSrv: AuthService) {}
	canActivate(): Observable<boolean> {
		const res = this.checkCanActivate();
		const logging = res.pipe(tap((b) => console.log('IsAuthenticatedGuard:', b)));
		return logging;
	}

	checkCanActivate(): Observable<boolean> {
		return this.authSrv.user$.pipe(
			filter((user) => user !== undefined),
			// map(user => (user && user.fb.emailVerified) ? true : false),
			map((user) => (user ? true : false)),
			take(1)
		);
	}
}
